<template>
  <a-form
    ref="formModal"
    :model="formState"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :label-align="'left'"
    :rules="rules"
    :validate-trigger="['change', 'blur']"
    @finish="onFinish"
    class="form-modal-btn"
  >
    <a-form-item name="name">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-main2 pr-[2px]">*</span>{{ t('menu.name') }}
      </p>
      <cds-input
        class="w-100 !text-ems-text1 ant-input-sm"
        :read-only="disabled"
        maxlength="255"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.name"
        :placeholder="t('menu.name')"
      />
    </a-form-item>
    <a-form-item name="clientId">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-main2 pr-[2px]">*</span>{{ t('menu.phan-he') }}
      </p>
      <a-select
        :disabled="formMode !== FORM_MODE.CREATE"
        :options="phanHe"
        :label-prop="'label'"
        :value-prop="'value'"
        v-model:value="formState.clientId"
        :placeholder="t('menu.phan-he')"
        @select="onSearch"
      />
    </a-form-item>
    <a-form-item name="perms">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">{{ t('menu.permission') }}
      </p>
      <cds-input
        class="w-100 !text-ems-text1 ant-input-sm"
        :read-only="formMode !== FORM_MODE.CREATE"
        maxlength="255"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.perms"
        :placeholder="t('menu.permission')"
      />
    </a-form-item>

    <a-form-item name="parentId">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
        <span class="text-ems-main2 pr-[2px]">*</span>{{ t('menu.parent-menu') }}
      </p>
      <cds-tree-select
        :readonly="formMode !== FORM_MODE.CREATE"
        :tree-data="menus"
        :field-names="fieldNames"
        v-model:value="formState.parentId"
        :placeholder="t('menu.parent-menu')"
        :disabled="formMode !== FORM_MODE.CREATE"
        :show-search="false"
      />
    </a-form-item>
    <a-form-item name="status">
      <p class="text-ems-gray500 text-[14px] pb-[4px]">
      {{ t('menu.status') }}
      </p>
      <cds-select
        :options="STATUS"
        :placeholder="t('menu.status')"
        v-model:value="formState.status"
        :disabled="formMode === FORM_MODE.VIEW"
      />
    </a-form-item>
    <div class="w-full flex justify-center mt-[34px]">
      <ClipButton
        v-if="!disabled"
        type="primary"
        size="small"
        style="height: fit-content"
        class="w-max !px-[30px]"
        type-html="submit"
      >
        <span class="text-sm text-ems-white">{{ t('common.save') }}</span>
      </ClipButton>
    </div>
  </a-form>
  <cds-modal
    :visible="visible"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="handleConfirm"
      @onClose="visible = false"
      :title="t('common.confirm_save')"
    ></ModalConfirm>
  </cds-modal>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRaw,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { FORM_MODE } from '@/util/common-constant';
import { required } from '@/util/validation';
import * as Icons from 'feather-icons';
import { mapFields } from 'vuex-map-fields';
import { formatDate } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
import { STATUS } from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import {trim} from '@/util/common-utils'
export default defineComponent({
  name: 'Button',
  components: {
    ClipButton,
    ModalConfirm
  },
  emits: ['saveSuccess'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { state, dispatch } = useStore();
    const formState = reactive(state.menu.menu);
    const formatDateVN = computed(() => formatDate);
    const formMode = computed(() => state.menu.formMode);
    const disabled = formMode.value === FORM_MODE.VIEW;
    const phanHe = [
      { label: t('menu.phan-he-1'), value: 1 },
      { label: t('menu.phan-he-2'), value: 2 },
    ];
    const rules = computed(() => {
      if (disabled) return {};
      return {
        name: [required(t('menu.name'))],
        clientId: [required(t('menu.phan-he'))],
        parentId: [required(t('menu.parent-menu'))],
      };
    });
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'name', value: 'id' };
    const setDisabled = (menu) => {
      menu.disabled = true;
      if (menu.children) {
        menu.children.forEach((m) => setDisabled(m));
      }
    };
    const icons = Icons.icons;
    const visible = ref(false)
    const menus = computed(() => {
      const menu =
        formState.clientId === 2 ? state.menu.menuVms : state.menu.menuEms;

      if (!disabled) return menu;
      menu.forEach((m) => setDisabled(m));
      return menu;
    });
    const onCancel = () => dispatch('menu/setVisibleButton', false);
    const onFinish = async () => {
      visible.value = true
    };
    const handleConfirm = async ()=>{
      try {
        formState.name = trim(formState.name)
        const data = _.cloneDeep(toRaw(formState));
        if (
          await dispatch(
            formMode.value === FORM_MODE.CREATE
              ? 'menu/createMenu'
              : 'menu/updateMenu',
            {
              ...data,
              type: 1,
            }
          )
        ) {
          emit('saveSuccess');
          visible.value = false
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }
    onMounted(() => {
      dispatch('menu/getMenuEms');
      dispatch('menu/getMenuVms');
    });
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      menus,
      fieldNames,
      formModal,
      onFinish,
      onCancel,
      disabled,
      icons,
      dispatch,
      titleModal: t('menu.choose-icon'),
      formatDateVN,
      formMode,
      t,
      phanHe,
      STATUS,
      FORM_MODE,
      visible,
      handleConfirm
    };
  },
  computed: {
    ...mapFields('menu', ['visibleIcon']),
  },
  methods: {
    iconClick() {
      this.dispatch('menu/preChooseIcon');
    },
  },
});
</script>

<style lang="scss">
.form-modal-btn .ant-input-number-input {
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity));
}
.form-modal-btn .ant-select-selection-item {
  color: #b4b4b4 !important;
}
.form-modal-btn .ant-select {
  height: 32px;
}
.form-modal-btn .ant-select .ant-select-selector {
  border: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.form-modal-btn .ant-select .ant-select-selection-placeholder {
  line-height: 26px !important;
}
</style>
